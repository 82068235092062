<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine whether each of the following statements are <b>True</b> or <b>False</b>.
      </p>

      <p class="mb-0">
        A 1s electron in a B atom has a smaller
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        than a 1s electron in a Be atom.
      </p>
      <true-false v-model="inputs.input1" class="mt-0 mb-2 pl-8" :disabled="!allowEditing" />

      <p class="mb-0">
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        increases from left to right across a period in the periodic table.
      </p>
      <true-false v-model="inputs.input2" class="mt-0 mb-2 pl-8" :disabled="!allowEditing" />

      <p class="mb-0">
        Going across a period,
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        increases causing an increase in atomic size.
      </p>
      <true-false v-model="inputs.input3" class="mt-0 mb-2 pl-8" :disabled="!allowEditing" />

      <p class="mb-0">
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        depends on the number of electrons in the atom.
      </p>
      <true-false v-model="inputs.input4" class="mt-0 mb-2 pl-8" :disabled="!allowEditing" />

      <p class="mb-0">
        In a F atom,
        <stemble-latex :content="'$\\text{Z}_{\\mathrm{eff}}$'" />
        for a 1s electron is greater than that for a 2s electron.
      </p>
      <true-false v-model="inputs.input5" class="mt-0 mb-2 pl-8" :disabled="!allowEditing" />

      <p class="mb-0">
        Electrons in d-orbitals are more effective at shielding than electrons in any other type of
        orbital.
      </p>
      <true-false v-model="inputs.input6" class="mt-0 pl-8" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import TrueFalse from './Question1/TrueFalse';

export default {
  name: 'Question30',
  components: {
    StembleLatex,
    TrueFalse,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
};
</script>
