<template>
  <v-radio-group v-bind="$attrs" v-on="$listeners">
    <v-radio
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :label="option.text"
    />
  </v-radio-group>
</template>

<script>
export default {
  name: 'TrueFalse',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      options: [
        {text: 'True', value: 'yes'},
        {text: 'False', value: 'no'},
      ],
    };
  },
};
</script>
